module.exports = {
    common: {
        login: 'Log In',
        register: 'Sign Up',
        loginregister: "Login/Register",
        logout: 'Log Out',
        tip: 'Tip',
        logintip: 'please log in first',
        expect: 'Look forward to expectation!',
        delete: 'Are you confirm to delete?',
        set: 'Set',
        update: 'Modify',
        slogan: "The world's top 50 digital currency exchanges",
        subslogan: "State agency guarantees asset security",
        more: "More",
        please: "Please",
        nodata: "No Records",
        pleaseselect: "Select",
        searchplaceholder: "Enter the coin symbol...",
        searchplaceholderswap: "Enter the swap symbol...",
        loading: "Loading...",
        close: "Close",
        ok: "OK",
        amount:"amount",
		fillComplete:"Please fill in the complete information",
        inGame: "Game",
        notice: "请用金贝钱包账号登录，如果您没有金贝钱包账号，请从下面链接下载金贝钱包app",
        userNamEmpty: '电话号码',
        capitalPass: 'Capital Password',
        capitalPassinput: 'please put in Password',
        usertip:'付款成功',
        moneyback:'MoneyBack',
        success:'success',
        fail:'failed',
        tibifirst:"Detail",
        message:"Message",
        service:'Customer Service',
        pleaseselectService: "Deposit Service",
        pleaseselectService1: "Withdraw Service",
        pleaseselectService2: "Transaction Service",
        tabbar1:'MAIN',
        tabbar2:'DEPOSIT',
        tabbar3:'WITHDRAW',
        tabbar4:'ACCOUNT',
        exchange: 'Exchange',
        exchangeAll: '全部',

        date: 'Time Picker',
        date1: 'Regiter Num',
        date2: 'Win Loss total',
        date3: 'Total Play',
        date4: 'ID',
        date5: 'Member Play',
        date6: 'Member Win Loss',
        date7: 'Validate bet Total',
        date8: 'Validate bet',
        date9: 'agent',
        game1: 'Live Baccarat',
        game2: 'Live Niu Niu',
        game3: 'Live Dragon Tiger',
        game4: 'Live Roulette',
        game5: 'Live ZhaJinHua',
        game6: 'Live SeDie',
        game7: 'Fishing',
        game8: 'Board Game',
        date10: 'Receiver ID:',
        notice21: 'Dear customer, Jinbei customer service will serve you wholeheartedly',
        notice22: '7 * 24 online',
        notice23: 'Reminder: At present, players can only transfer money to the agent account !!',
        loginman: 'to login',
        male:'male',
        female: 'female'
    },
    
    uc: {
        finance: {
            center: 'Safety',
            personalassets: 'Net',
            swapassets: 'Address',
            swapassets1: 'Account',
            billdetail: 'Transactions',
            recordetail: 'D&W Records',
            tradetail: 'ammount left',
            paydividends: '持币分红',
            invitingmining: 'amount',
            charge: 'deposit',
            pickup: 'withdraw',
            copy: 'copy',
            copysuccess:'copy success',
            copyfailed:'copy failed',
            notice:"transaction fee paid buy member self",
            notice1:"every address is unique, if your deposition have not been add to you account, please contact us",
            notice11:"if your deposition have not been add to you account, please contact us",
            transfee:"Transaction Fee",
            timet:"time",
            emailcodetip: 'Setup Capital Password',
            gamedetail: 'Game Details',
            gametype:'Game',
            game0: 'WALI',
            game81: 'Live Baccarat',
            game82: 'Live jisu Baccarat',
            game83: 'Live gongmi Baccarat',
            game84: 'Live jinmi Baccarat',
            game85: 'Live douniu',
            game86: 'Live zhajinhua',
            game87: 'Live niuniu',
            game88: 'Live longhu',
            game89: 'Live saibao',
            game90: 'Live lunpan',
            game91: 'Live sedie',
            game92: 'Live baoxian Baccarat',
            game100: 'WALI Sports',
            bet:'BET',
            notice111: "Contact customer service",
            validBet: 'VALIDBET',
            profit: 'PROFIT',
            addUaddress: 'USDT address',
            addaddress:'Add Info',
            trans: 'Transaction',
            agentdata: 'AgentData',
            promotion: 'Promotion',
            promotion1: 'Promotion Code',
            promotion2: 'save code',
            promotion3: 'Promotion URL',
            notice123:'infomation is not completed' ,
            notice1234:"handle successful, please wait",
            notice12345:"Put in Amount",
            personalInfo: 'Info',
            personalInfo1: 'The name must be the same as the name of the bank card holder, otherwise the withdrawal cannot be made',
            personalInfo2: 'Please select gender',
            personalInfo3: 'Add birthday to make sure you are over 18',
            personalInfo4: 'Bind mobile phone number to protect account security',
            personalInfo5: 'For your privacy and security, the information cannot be modified after confirmation, if you need help, please',
            xx1 : 'Recharge order',
            xx2 : 'Withdraw order',
            xx3 : 'Status',
            xx4 : 'Under review',
            xx5 : 'success',
            xx6 : 'Fail',
            xx7 : 'Name',
            xx8: 'Account',
            xx9 : 'Name',
            xx10 : 'Account',
            xx11 : 'Status',
            xx12 : 'Time',
            xx13 : 'Account',
            xx14 : 'None yet',
            xx15 : 'Member transfer',
            xx17 : 'Transfer and withdraw cash',
            xx18 : 'Transfer and recharge！！',
            xx19 : 'Internet Deposit',
            xx20 : 'Internet Deposit',
            xx21 : 'U wallet withdrawal',
            xx22 : 'Online Withdrawal',
            xx23 : 'Deposit',
            xx24 : 'Withdraw',
            xx25 : 'Bonus',
            xx26 : 'Make up points',
            xx27 : 'Points deducted',
            xx28 : 'Register red envelope',
            xx29 : 'Order No.',
            xx30 : 'time',
            xx31 : 'Balance after transaction',
            xx32 :'Commission Rate',
            xx33 :'Dear Jinbei members',
            xx34: 'Downline Member',
            xx35: 'New Members This Month',
            xx36: 'Active Members This Month',
            xx37: 'This Months Net Win/Loss',
            xx38: 'Rebate Rate',
            xx39: 'Commission this month',
            xx40: 'Promote QR code',
            xx41: 'Dedicated Domain Name',
            xx42: 'Downline Member List',
            xx43: 'Commission reports',
            xx44: 'Search account',
            xx45: 'Member Account',
            xx46: 'Last Login',
            xx47: 'Register date',
            xx48: 'This Periods Commission',
            xx49: 'Total W/L',
            xx50: 'Total Rebate',
            xx51: 'Bonus',
            xx52: 'Administrative Deduction',
            xx53: 'Transaction Fee',
            xx54: 'VIP Extra Bonus',
            xx55: 'VIP Bonus',
            xx56: 'Commission Rate',
            xx57: 'Financial Report',
            xx58: 'This Periods Commission',
            xx59: 'Platform fees',
            xx60: 'Withdraw account',
            xx61: 'cam Deposit',
            xx62: 'cam Withdraw',
            xx63 : 'Processing',
            xx64 : 'payment successful',
            xx65 : 'Payment failed',
            xx66 : 'Bill completion time',
            xx67 : 'Deposit method',
            xx68 : 'Currency',
            xx69 : 'Withdrawal method',
            platform: 'Gaming platform',
            bitStatus: 'Status',
            xx70 : 'Valid Bets',
            xx71 : 'The game is in progress',
            xx72 : 'Invalid bet',
            xx73 : 'Starting time',
            xx74 : 'End Time',
            xx75 : 'Betting details',
            xx76 : 'Order No',
            xx77 : 'Account',
            xx78 : 'Table number',
            xx79 : 'Results',
            xx80 : 'Game code',
            xx81 : 'Language',
            xx82 : 'Welcome to JBbet customer service',
            xx83 : 'HI, Dear customers',
            xx84 : 'Cambodian,English customer service',
            xx85 : 'Vietnam customer service',
            xx86 : 'Chinese customer service',
            xx87 : 'English customer service',
            xx88 : 'Bank card name',
            xx89 : 'Account No',
            xx91 : 'Bank name',
            xx92 : '24/7 Services',
            xx93 : 'Add bank card',
            xx94 : 'Please bind the withdrawal address (TRC20) to ensure the safety of funds',
            xx95 : 'Are you sure you want to return to the lobby? If the game currency is not returned correctly, please use the one-click refund function on my page',
            xx96 : 'League name',
            xx97 : 'home team',
            xx98 : 'away team',
            xx99 : 'Fraction',
            xx100 : 'All information is required',
            xx101: 'About JBbet',
            xx102: 'JBbetIt is a favorite gambling and entertainment venue for many people. This is a highly regarded playground among todays top reputable and premium betting sites. JBbet not only enjoys a high reputation in Southeast Asia, but also occupies an important position in the gambling market of the entire Asian market.。',
            xx103: 'The birth of JBbet',
            xx104: 'Despite its recent popularity, JBbet has been operating in the Asian market for many years. JBbet was established in 2005 and officially operates in Cambodia. Thanks to PAGCOR for its cooperation in issuing the operating license. This bookmaker has many gambling games that are popular among players.',
            xx105: 'JBbet products and services',
            xx106: 'In addition to sports games and live entertainment, we also cooperate with many major entertainment companies. Therefore, we always ensure that we provide the highest quality entertainment and gambling services and products. Not only does it bring a reputable and professional betting market. There is also a diverse playground, bringing the best options for everyone。',
            xx107: 'Sports competition',
            xx108: 'The most popular betting products. The world of sports betting, including football, basketball, volleyball, tennis... is fascinating. Players can place bets and watch games from the biggest season in the world. Satisfy your betting passion with accurate odds and attractive rebate offers。',
            xx109: 'Live casino',
            xx110: 'Unlike traditional casino tables, today we also have live casinos. After registering an account at JBbet, players can join the live casino with hot girls. Youll join the table with real people via a live screen. Not only does it increase attraction and fun, it also creates more authenticity and professionalism for players',
            xx111: 'Slots',
            xx112: 'JBetOne of the most attractive entertainment products is slot machine games. We offer hundreds of slot machines from major game manufacturers around the world. Provide diverse experiences through different colors, content and prizes。',
            xx113: 'Poker',
            xx114: 'In addition to live entertainment, JBbet also offers gambling games with eye-catching 3D graphics. From traditional board and card games to dragon and tiger, baccarat, blackjack and other games,...',
            xx115: 'Fishing game',
            xx116: 'Fishing is a very familiar game that is both highly entertaining and a great way to make extra money. Come to JBbet, fishing becomes more diverse and the content and graphics are more unique。',
            xx117: 'Advantages of JBbet',
            xx118: 'Possess a legal license issued by the European Malta MGA. Registered in the British Virgin Islands, it is an internationally recognized legal company. Therefore, players participating in betting and casinos here do not have to worry about legality. JBbet is equipped with advanced technical equipment and adopts professional security system. Therefore, all player information will be safe and will not be exposed to the outside world. If there is an unauthorized third party, we will promptly detect and prevent it. In addition, we always have the most professional customer service staff. Ensure 24/7 support and answers to all your questions. This advantage is also what makes us most confident when introducing JBbet to international friends. Therefore, please contact customer service for advice and answers when needed。',
            xx119: 'We hope you can get to know us better. do not forget. Register a JBbet account to be able to participate in this exciting betting playground',
            xx120: 'This currency is not supported. Please go to the wallet page to exchange or switch currencies.',
            xx121: 'Please fill in your bank card name, as shown below',
            xx122: 'USDT address (TRC20)',
            xx123: 'Address Management',
            xx124: 'All records',
            xx125: 'Recharge record',
            xx126: 'Withdrawal history',
            xx127: 'Cancel',
            xx128: 'Applying',
            xx129: 'Confirmed',
            xx130: 'Has been rejected',
            xx131: 'Sports records',
            xx132: 'Game records',
            xx133: 'Result',
            xx134: 'Closed',
            xx135: 'Not end yet',
            xx136: 'Cancel',
            xx137: 'Yesterday',
            xx138: 'Today',
            xx139: 'Last 7 days',
            xx140: 'Nearly 30 days',
            xx141: 'Single bet',
            xx142: 'Multiple games',
            xx143: 'Champion',
            xx144: 'Date of your choice',
            xx145 : 'English customer service',
            xx146 : 'hide',
            xx147 : 'Test deposit',
            xx148 : 'Bet Details',
            xx149 : 'CMD Sports',
            xx150 : 'Saba Sports',
            xx151 : 'GamingSoft',
            xx152 : 'Match date',
            xx153 : 'USDT transfer out',
            xx154 : 'USDT transfer in',
            xx155 : 'บริการลูกค้าไทย',
            xx156 : 'Layanan pelanggan indonesia',
            xx157 : 'JDB Slots',
            xx158 : 'PG Slots',
            xx159 : 'PP Slots',
            xx160 : 'NT Slots',
            xx161 : 'JDB Flshing',
            xx162 : 'HI, Welcome to your arrival',
            xx163 : 'Don’t have an account?',
            xx164 : 'My currency',
            xx165 : 'Exchange',
            xx166 : 'Register red envelope',
            xx167 : 'CMD System reconciliation',
            xx168 : '游戏买断',
            xx169 : '游戏买入',
            xx170 : 'Progressive jackpot',
            xx171 : 'Bonus',
            xx172 : 'Cancel bet',
            xx173 : '回滚投注',
            xx174 : 'Game settlement',
            xx175 : 'Game betting',
            xx176 : 'Refund if withdrawal fails',
            xx177 : 'Member transfer',
            xx178 : 'Points deducted',
            xx179 : 'Commission Rate',
            xx180 : 'Make up points',
            xx181 : 'Bonus',
            xx182 : 'CAM Deposit ',
            xx183 : 'Huione Deposit',
            xx184 : 'Udun Deposit',
            xx185 : 'Manual Deposit',
            xx186 : 'Manual Withdrawal',
            xx187 : 'Huione Withdrawal',
            xx188 : 'Udun Withdrawal',
        },
        login: {
            noaccount: 'No Account？',
            register: 'Sign Up',
            login: 'Log In',
            welcomelogin: 'Welcome',
            usertip: 'Enter phone number',
            pwdtip: 'Enter Password',
            validatecodeload: 'Loading verification code',
            validatemsg: 'Please finish verification first',
            forget: 'Forget password?',
            loginvalidate: 'Please input email or phone number',
            pwdvalidate1: 'Please input password',
            pwdvalidate2: 'Password length not less than six',
            success: 'Success',
            goregister: "Regist",
            getlostpwd: "Forget Password",
            somebody: "Nobody",
            nouser: "User not exist",
            normaluser:'對方用戶不支持轉賬',
        },
        regist: {
            hasaccount: 'Have a Account？To Log In',
            login: 'Log in',
            username: 'Username',
            country: 'Country',
            smscode: 'Sms verification code',
            sendcode: 'Send',
            resendcode: "Resend(",
            pwd: 'Password',
            repwd: 'Confirm password',
            confrimpwd: 'Confrim password',
            agreement: 'I have read and agree',
            userprotocol: 'User Agreement',
            regist: 'Sign up',
            teltip: 'Enter tel',
            telerr: 'Tel format error,enter again',
            emailtip: 'Enter email',
            emailerr: 'password error,enter again',
            confirmpwdtip: 'Enter confirm password',
            confirmpwderr: 'Two passwords not same!',
            telregist: 'Tel Regist',
            emailregist: 'Email Regist',
            usernametip: 'Enter username',
            usernamemsg: 'Username length not less than 3,not more than15',
            countrytip: 'Select country',
            smscodetip: 'Enter sms verification code',
            pwdtip: 'Enter login password',
            pwdmsg: 'Password length not less than 6',
            telno: 'Tel',
            email: 'Email',
            agreementtip: 'Click to agree',
            modaltitle: 'Please verification',
            promotion: 'Agent ID(Optional)',
			china: 'china',
            singapore: 'singapore',
            korea: 'korea',
            japan: 'japan',
            thailand: 'thailand',
            russia: 'russia',
            uk: 'uk',
            vietnam: 'vietnam',
            india: 'indonesia',
            italy: 'italy',
            hk: 'hk',
            malaysia: 'malaysia',
            taiwan: 'taiwan',
            turkey: 'turkey',
            germany: 'germany',
            france: 'france',
            spain: 'spain',
            america:'America',
            mexico:'mexico',
            cambodia: 'cambodia',
            wenlai: 'Brunei',
        },
        forget: {
            title: "Find Password",
            loginman: 'login first please',
            hasaccount: 'Have a Account？To Log In',
            login: 'Log In',
            sendcode: 'Send',
            newpwd: 'Enter new password',
            confirmpwd: 'Enter new password again',
            confirmpwd11: 'Receiver ID',
            save: 'Submit',
            pwdvalidate1: 'Enter confirm password',
            pwdvalidate2: 'Password not same!',
            resettelpwd: 'Reset login password',
            resetemailpwd: 'Reset Transaction password',
            newpwdtip: 'Enter new password',
            pwdvalidate3: 'Password length not less than six',
            telno: 'Tel number',
            smscode: 'Sms verification code',
            teltip: 'Enter tel',
            smscodetip: 'Enter sms verification code',
            email: 'Email',
            addresstip221: 'HUI ONE account',
            walletaddress21: 'amount must be greater than 0',
            huioneaddress: 'HUI ONE',
            emailcode: 'Email verification code',
            emailtip: 'Enter email',
            emailcodetip: 'Enter Transaction password',
            resetpwdsuccess: 'Reset password success',
            smswarn: 'Please check for SMS',
            addresstip: 'wallet address',
            emailcodetip1: 'please setup capital password',
            emailcodetip11: 'set up your usdt wallet address please',
            walletaddress: 'Wallet Address',
            walletaddress1: 'Wallet Address',
            walletaddress2: 'Total Amount',
            userID: 'Account ID',
            addresstip21: 'put in receiver ID',
            upload: 'Upload Invoice',
            addresstip211: 'receiver ID',
            addresstip31: 'HUIONE NAME',
            addresstip41: 'HUIONE PHONE',
            addresstip311: 'NAME',
            addresstip411: 'PHONE',
            addresstip321:'Put in name'
        },
    }
}
