<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data(){
    return {
      iscover : 0

    }
  },
  created(){
    window.addEventListener("orientationchange",function(){
        if (window.orientation != 0) {
          this.iscover == 1
        }else this.iscover = 0
    });
  }
}
</script>

<style>
html body #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100%;
  width: 100%;
color:aliceblue;

}

.cover{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  z-index: 10000;
  color: aliceblue;
  font-size: x-large;
  text-align: center;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>


<style lang="scss" src="./assets/scss/global.scss" />

