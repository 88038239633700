<template>
	<div class='class_tree clearfix'>
		<ul class='class_tree_nav'>
			<li
        v-for='(item ,index) in list'
        :key='item.id'
       
				@click='navclick(index)'>
				<img v-if="item.name === 'hot' && navActive === index" src="../assets/newImage/quanbu12.png" alt="" style="width:70px;"/>
        <img v-else-if="item.name === 'hot' && navActive !== index" src="../assets/newImage/quanbu11.png" alt="" style="width:70px;"/>
        <img v-if="item.name === 'game' && navActive === index" src="../assets/newImage/dy12.png" alt="" style="width:70px;"/>
        <img v-else-if="item.name === 'game' && navActive !== index" src="../assets/newImage/dy11.png" alt="" style="width:70px;"/>
        <img v-if="item.name === 'duizhan' && navActive === index" src="../assets/newImage/duiz12.png" alt="" style="width:70px;"/>
        <img v-else-if="item.name === 'duizhan' && navActive !== index" src="../assets/newImage/duiz11.png" alt="" style="width:70px;"/>
        <img v-if="item.name === 'tiyu' && navActive === index" src="../assets/newImage/tiyu12.png" alt="" style="width:70px;"/>
        <img v-else-if="item.name === 'tiyu' && navActive !== index" src="../assets/newImage/tiyu11.png" alt="" style="width:70px;"/>
        <img v-if="item.name === 'laohuj' && navActive === index" src="../assets/newImage/laohuj12.png" alt="" style="width:70px;"/>
        <img v-else-if="item.name === 'laohuj' && navActive !== index" src="../assets/newImage/laohuj11.png" alt="" style="width:70px;"/>
        <img v-if="item.name === 'fish' && navActive === index" src="../assets/newImage/fish12.png" alt="" style="width:70px;"/>
        <img v-else-if="item.name === 'fish' && navActive !== index" src="../assets/newImage/fish11.png" alt="" style="width:70px;"/>
        <img v-if="item.name === 'shixun' && navActive === index" src="../assets/newImage/shixun12.png" alt="" style="width:70px;"/>
        <img v-else-if="item.name === 'shixun' && navActive !== index" src="../assets/newImage/shixun11.png" alt="" style="width:70px;"/>
			</li>
		</ul>
		<div class='class_tree_content'>
		
			<div class='class_tree_items_wrap clearfix'>
				<div v-for='(item, i) in goods' :key='i'  @click='classClick(item.id)' >
					<div class='class_tree_item_img'>
            <img v-if="item.name === 'wuhui'" src="../assets/newImage/wuhui.png" alt=""/>
            <img v-else-if="item.name === 'longhu'" src="../assets/newImage/longhu.png" alt="" />
            <img v-else-if="item.name === 'blackred'" src="../assets/newImage/blackred.png" alt="" />
            <img v-else-if="item.name === 'beast'" src="../assets/newImage/beast.png" alt="" />
            <img v-else-if="item.name === 'saizi'" src="../assets/newImage/saizi.png" alt="" />
            <img v-else-if="item.name === 'barrca'" src="../assets/newImage/barrca.png" alt="" />
            <img v-else-if="item.name === 'benz'" src="../assets/newImage/benz.png" alt="" />
            <img v-else-if="item.name === 'zajinhua3'" src="../assets/newImage/zajinhua3.png" alt="" />
            <img v-else-if="item.name === 'tongzi'" src="../assets/newImage/tongzi.png" alt="" />
            <img v-else-if="item.name === 'yuxia'" src="../assets/newImage/yuxia.png" alt="" />
            <img v-else-if="item.name === 'niuniu'" src="../assets/newImage/niuniu.png" alt="" />
            <img v-else-if="item.name === 'landload'" src="../assets/newImage/landload.png" alt="" />
            <img v-else-if="item.name === 'zajinhua'" src="../assets/newImage/zajinhua.png" alt="" />
            <img v-else-if="item.name === 'niuniu4'" src="../assets/newImage/niuniu4.png" alt="" />
            <img v-else-if="item.name === 'niuniu5'" src="../assets/newImage/niuniu5.png" alt="" />
            <img v-else-if="item.name === 'niuniu6'" src="../assets/newImage/niuniu6.png" alt="" />
            <img v-else-if="item.name === 'niuniu7'" src="../assets/newImage/niuniu7.png" alt="" />
            <img v-else-if="item.name === 'niuniu9'" src="../assets/newImage/niuniu9.png" alt="" />
            <img v-else-if="item.name === 'niuniu10'" src="../assets/newImage/niuniu10.png" alt="" />
            <img v-else-if="item.name === '21'" src="../assets/newImage/21.png" alt="" />
            <img v-else-if="item.name === 'run'" src="../assets/newImage/run.png" alt="" />
            <img v-else-if="item.name === 'poke'" src="../assets/newImage/poke.png" alt="" />
            <img v-else-if="item.name === 'majong'" src="../assets/newImage/majong.png" alt="" />
            <img v-else-if="item.name === 'shisansui'" src="../assets/newImage/shisansui.png" alt="" />
            <img v-else-if="item.name === 'sangong'" src="../assets/newImage/sangong.png" alt="" />
            <img v-else-if="item.name === 'tiyu'" src="../assets/newImage/tiyu.png" alt="" />
            <img v-else-if="item.name === 'caisheng'" src="../assets/newImage/caisheng.png" alt="" />
            <img v-else-if="item.name === 'chain'" src="../assets/newImage/chain.png" alt="" />
            <img v-else-if="item.name === 'fish'" src="../assets/newImage/fish.png" alt="" />
            <img v-else-if="item.name === 'longhushixun'" src="../assets/newImage/longhushixun.png" alt="" />
            <img v-else-if="item.name === 'lunpanshixun'" src="../assets/newImage/lunpanshixun.png" alt="" />
            <img v-else-if="item.name === 'niuniushixun'" src="../assets/newImage/niuniushixun.png" alt="" />
            <img v-else-if="item.name === 'sedieshixun'" src="../assets/newImage/sedieshixun.png" alt="" />
            <img v-else-if="item.name === 'saizishixun'" src="../assets/newImage/saizishixun.png" alt="" />
            <img v-else-if="item.name === 'zajinhua2'" src="../assets/newImage/zajinhua2.png" alt="" />
            <img v-else-if="item.name === 'baccaratshixun'" src="../assets/newImage/baccaratshixun.png" alt="" />
          </div>
					<!-- <div class='class_tree_item_name'>{{item.name}}</div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  name: 'class-tree',

  model: {
    prop: 'activeIndex'
  },

  props: {
    activeIndex: {
      type: Number,
      default: 0
    },
    list: {
      type: Array,
      default: () => []
    }
  },

  data() {
    const navActive =
      this.activeIndex >= this.list.length ? 0 : this.activeIndex
    return {
      navActive
    }
  },

  computed: {
    goods() {
      const list = this.list,
        navActive = this.navActive
      return list.length ? list[navActive].children : []
    }
  },

  methods: {
    allClick() {
      this.$emit('all-click')
    },
    navclick(i) {
      this.navActive = i
      this.$emit('nav-click', i)
    },
    classClick(id) {
      this.$emit('class-click', id)
    }
  }
}
</script>

<style lang='less' scoped>
// @import '../assets/scss/mixin'
.class_tree {
  position: relative;
  background-color: rgb(51, 49, 49);
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 100%;
  box-sizing: border-box;
}
.class_tree_nav {
  float: left;
  width: 80px;
  height: 100%;
  background-color: rgb(51, 49, 49);
  overflow: scroll;
  > li {
    // @include one-border
    height: 50px;
    line-height: 50px;
    text-align: center;
    margin-bottom: 30px;
    // border-left: 2px solid $bg-color
  }
  > li.active_nav {
    background-color: #fff;
   
  }
}
.class_tree_content {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  .class_tree_all {
    text-align: right;
    padding-right: 10px;
    height: 40px;
    line-height: 40px;
   
  }
 
  .class_tree_items_wrap {
    
    text-align: center;
    > div {
      float: left;
      // padding-right: 5%;
      box-sizing: border-box;
      width: 33.333%;
      // margin-bottom: 20px;
    }
    img {
      max-width: 100%
    }

    .class_tree_item_img {
      display: inline-block;
      max-width: 100%;
      width: 100px;
      height: 80px;
    }
    .class_tree_item_name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
