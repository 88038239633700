import Vue from "vue"
import Vuex from "vuex"
import router from "./router"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    member: null,
    lang: "",
    exchangeSkin: "night",
    loginTimes: null,
    mobileTo: "",
    code: "",
    isThird: 0,
    orderNo: "",
    paymethod: "",
    amount: 0,
    cytype: "",
    isGoWC: false,
    isNobody: false,
  },
  mutations: {
    setlang(state, lang) {
      state.lang = lang
      localStorage.setItem("LANGUAGE", lang)
    },
    setMember(state, member) {
      state.member = member
      localStorage.setItem("MEMBER", JSON.stringify(member))
    },
    setNobody(state, value) {
      state.isNobody = value
    },
    setMobile(state, mobile) {
      state.mobileTo = mobile
    },
    setOrderNo(state, orderNo) {
      state.orderNo = orderNo
    },
    setPayMethod(state, pay) {
      state.paymethod = pay
    },
    setAmount(state, amt) {
      state.amount = amt
    },
    setCurrency(state, cyytype) {
      state.cytype = cyytype
    },
    setCode(state, code) {
      state.code = code
    },
    setThird(state, third) {
      state.isThird = third
    },
    setIsGoWC(state, isnow) {
      state.isGoWC = isnow
    },
  },
  actions: {
    toggleLoginAlert() {
      Vue.prototype.$Modal.error({
        title: "温馨提示",
        content: "用户未登录或已过期",
        okText: "去登录",
        onOk: () => {
          router.replace('/')
        },
      })
    },
  },
  modules: {},
  getters: {
    member(state) {
      return state.member
    },
    isLogin(state) {
      console.log(state.member);
      console.log(localStorage.getItem('MEMBER'));
      if (state.member == null || localStorage.getItem('MEMBER') == null) {
        return false
      }
      return true
      // return state.member != null || localStorage.getItem("MEMBER") != null
    },
    isNobody(state) {
      return state.isNobody
    },
    lang(state) {
      return state.lang || localStorage.getItem("LANGUAGE")
    },
    loginTimes(state) {
      return state.loginTimes
    },
    mobileTo(state) {
      return state.mobileTo
    },
    code(state) {
      return state.code
    },
    isThird(state) {
      return state.isThird
    },
    isGoWC(state) {
      return state.isGoWC
    },
    orderNo(state) {
      return state.orderNo
    },
    payMethod(state) {
      return state.paymethod
    },
    amount(state) {
      return state.amount
    },
    currencyType(state) {
      return state.cytype
    },
  },
})
