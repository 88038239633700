export default {
    common: {
        area: '/uc/support/country'
    },
    uc: { //会员中心接口
        login: '/user/login',
        register: '/user/register',
        gameRecorder: '/game/getUserRecordList',
        wallet: '/user/getWithdrawalAddressList',//获取当前用户绑定的提现账号
        captcha: '/sms/sendSmsCode',
        identification: '/user/addWithdrawalAddress', //添加提现地址usdt
        submetExchange: '/funds/changeMoney',
        // apply: '/thirdGame/downScore', //商家认证申请
        announcement: '/user/resetPassword', //重新设置密码
        paydividends: "/funds/comSkAcctQuery", //获取币种列表
        mylistrecord: "/user/checkJbPassword", //確認體現
        activitylist: "/user/setJbPassword", // 设置資金密碼
        memberactivity: "/funds/queryfunds", // 用户流水列表
        attendActivity: "/funds/initiateAWithdrawal", // usdt 提现
        personalWallet: "/user/getPersonalWallet", // 获取用户余额
        toppromotion: "/app-notices/queryMarqueeAnnounce", // 跑马灯信息
        getfreecard: "/funds/addWithdrawOrder", // 发起提现订单
        exchangecard: "/funds/transfer", // 转账
        mycardlist: "/app-ad-info/banner/list", // 轮播图
        toppromotionmonth: "/user/logout", // 用户登出
        toppromotionweek: "/user/updatePassword", // 重置登录密码
        memberInfo: "/user/getPersonal", // 获取用户信息
        // mypromotionrecord: "/thirdGame/downScore", //获取推广邀请人记录
        myInnovationOrderList: "/user/updateJbPassword", // 更新资金密码
        // myInnovationMinings: "/thirdGame/upScore", // 获取我的矿机列表
        // myInnovationLocked: "/thirdGame/getGameUrl", // 获取我的矿机列表
        // showMeTheMoney: "/capi/access/depositList",
        // initiateATransfer: '/capi/transfer/initiateATransfer',
        // verifyPwd: '/capi/user/verifyPwd',
        // confirmTransfer: '/capi/transfer/confirmTransfer',
        joinList: '/app-activity/join/list',  //每次进主页请求，返回当前用户可参与活动
        receiveAward: '/app-activity/receiveAward',  //领取奖励
        // checkuser: "/funds/getUser", // 发起体现
        agentData: "/app-agent/getAgentData", // 获取代理数据
        // recharge: "/funds/jbcn-deposit", //充值
        // rmbWithdraw: "/funds/jbcn-withdraw", // rmb 提现
        getDepositList: '/funds/getDepositList', // 获取存款列表
        getWithdrawList: '/funds/getWithdrawalList', // 获取提现列表
    },
    game:{
        bet: "/siming/bet", //下注
        getConfig: "/siming/getConfig", //获取游戏配置
        getTables: "/siming/getTables", //获取桌子
        intoTable: "/siming/intoTable", //进入桌子
        quitTable: "/siming/quitTable", //退出桌子
    }

}
