import Vue from 'vue'
import VueRouter from 'vue-router'
import About from './views/About.vue'
// import store from './store'

Vue.use(VueRouter)
  const routes = [
  {
    path: '/about',
    name: 'About',

    component: About
  },
  {
    path: '/HomeList',
    name: 'HomeList',
    component: () => import(/* webpackChunkName: "about" */ './views/HomeList.vue')
  },
  {
    path: '/',
    name: 'Home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ './views/Home.vue')
  },
  {
    path: '/MobileRegister',
    name: 'register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ './views/MobileRegister.vue')
  },
  {
    path: '/Recharge',
    name: 'recharge',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ './views/Recharge.vue')
  },
  {
    path: '/Withdraw',
    name: 'withdraw',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ './views/Withdraw.vue')
  },
  {
    path: '/FindPwd',
    name: 'findpwd',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ './views/FindPwd.vue')
  },
  {
    path: '/me',
    name: 'me',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ './views/me.vue')
  },
  {
    path: '/finde',
    name: 'finde',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ './views/finde.vue')
  },
  {
    path: '/set',
    name: 'set',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ './views/set.vue')
  },
  {
    path: '/loginset',
    name: 'loginset',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ './views/loginset.vue')
  },
  {
    path: '/setpage',
    name: 'setpage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ './views/setpage.vue')
  },
  {
    path: '/cart',
    name: 'cart',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ './views/cart.vue')
  },
  {
    path: '/category',
    name: 'category',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ './views/category.vue')
  },
  {
    path: '/GamePage',
    name: 'gamePage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ './views/GamePage.vue')
  },
  {
    path: '/gamedetail',
    name: 'gamedetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ './views/gamedetail.vue')
  },
  {
    path: '/trans',
    name: 'Transaction',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ './views/trans.vue')
  },
  {
    path: '/agentdata',
    name: 'AgentData',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ './views/agentdata.vue')
  },
  {
    path: '/promotion',
    name: 'Promotion',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ './views/promotion.vue')
  },
  {
    path: '/customservice',
    name: 'CustomService',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ './views/customservice.vue')
  },
  {
    path: '/dwrecords',
    name: 'DWrec',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ './views/DWrecords.vue')
  },
  {
    path: '/subdetail',
    name: 'SubDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ './views/subGamedetail.vue')
  },
  {
    path: '/tgTransition',
    name: 'TgTransition',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ './views/tgTransition.vue')
  },
  {
    path: '/personalInfo',
    name: 'personalInfo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ './views/personalInfo.vue')
  },
  {
    path: '/gameTable',
    name: 'gameTable',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ './views/gameTable.vue')
  },
  {
    path: '/exchange',
    name: 'Exchange',
    meta: {
      index: 0,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ './views/exchange.vue')
  },
  // { path: '/findPwd', component: resolve=>(require(["../pages/uc/findpwd"],resolve)) },
]

const router = new VueRouter({

  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    // console.log(to)
    // console.log(from)
    // console.log(savedPosition)
    return { top: 0 }
  },
})

// router.beforeEach(async (to, from, next) => {

//   if (store.state.member == null) {
//     console.log(from);
//     next()
//   }

//   else if (
//     // make sure the user is authenticated
//     store.state.member.isNobody && router.app.$store.state.member.isNobody == true &&
//     // ❗️ Avoid an infinite redirect
//     to.name != 'About'
//   ) {
//     // redirect the user to the login page
//     return { name: 'About' }
//   }
// })

export default router
