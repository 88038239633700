// 引入Vue
import Vue from 'vue'
// 引入红包组件
import bonusItem from './bonusItem.vue'

// 红包实例
let packet

// 组件挂载
function createItem () {
    // 用vue渲染红包组件并挂载
    const vnode = new Vue({
        render: h => h(bonusItem)
    }).$mount()

    // 将组件添加到body上
    document.body.appendChild(vnode.$el)

    // 返回当前组件的实例
    return vnode.$children[0]
}

export function showPacket (args) {
    // 创建组件
    packet = createItem(args)

    // 将组件实例暴露出去
    return packet
}
export default showPacket