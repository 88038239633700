<template>
  <div class="tab-bar-item" @click="itemClick">
    <div v-if="!isActive"><slot name="item-icon"></slot></div>
    <div v-else><slot name="item-icon-active"></slot></div>
    <div class="tab-bar-item-text"><slot name="item-text"></slot></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: 'TabBarItem',
  props: {
    path: String,
    // activeColor: {
    //   type: String,
    //   default: "red",
    // },
  },
  data() {
    return {
      // isActive:true
    }
  },
  computed: {
    ...mapGetters(["isLogin"]),
    isActive() {
      return this.$route.path.indexOf(this.path) !== -1 && this.$route.path.length === this.path.length
    },
    // activeStyle() {
    //   return this.isActive ? { color: this.activeColor } : {}
    // },
  },
  methods: {
    itemClick() {
      if (!this.isLogin) {
        this.$Modal.error({
          title: this.$t("common.tip"),
          content: this.$t("uc.forget.loginman"),
          okText: "去登录",
          onOk: () => {
            this.$router.replace("/")
          },
        })
        return
      }

      this.$router.replace(this.path)
    },
  },
}
</script>

<style lang="scss" scoped>
.tab-bar-item {
  flex: 1;
  text-align: center;
  height: 60px;
  font-size: 14px;
  color: aliceblue;

  &-text {
    color: #fff;
    font-weight: bold;
  }
}

/*  设置图片大小 */
.tab-bar-item img {
  margin-top: 5px;
  width: 25px;
  height: 24px;
  /* 去除图片下面的空间，默认的3像素 */
  vertical-align: middle;
  margin-bottom: 2px;
}
</style>
