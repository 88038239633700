<template>
    <div class="about">
        <div class="about__head">
            <div class="about__head--avatar">
                <img src="../assets/ting/home.png" alt="" @click="goHomeList">
                <!--          <img :src="src" alt="" @click="goHomeList">-->
            </div>
            <div class="about__head--service" @click="gotoService">
                <!--        <img src="../assets/about/service.png" alt="Service">-->
                <img slot="item-icon-active" src="../assets/netmage/asset_1.png" alt=""/>
                <span style="font-size: 12px">个人中心</span>
            </div>
        </div>
        <div class="about__head--info">
            <div>
                <img src="../assets/about/account.png" alt="Account">
                <div>{{ userinfo && userinfo.userNo }}</div>
            </div>
            <div>
                <img src="../assets/about/amount.png" alt="Amount" style="width: 24px">
                <div>{{ this.moneybab.amount }}</div>
            </div>
        </div>
        <div class="about__content">
            <!--      <div class="about__banner">-->
            <!--        <slider animation="fade" :control-btn="false" :height="bannerHeight">-->
            <!--          <slider-item v-for="(pic, index) in picList" :key="index">-->
            <!--            <img :src="pic.coverImg" alt="">-->
            <!--          </slider-item>-->
            <!--        </slider>-->
            <!--      </div>-->
            <div class="about__propaganda">
                <div class="about__propaganda--bg">
                    <PaoMaDeng :delay="0.5" :speed="30" :content="msgList" class="--wrap">
            <span v-for="(item, index) in msgList" :key="index">
              {{ item.content }}
            </span>
                    </PaoMaDeng>
                </div>
            </div>
            <!--      <div class="about__midhead">-->
            <!--        <div>-->
            <!--          <img :src="src" alt="">-->
            <!--        </div>-->
            <!--      </div>-->
            <div class="about__tables">
                <Spin v-if="tableLoading" fix class="demo-spin-col">
                    <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
                    <div>台桌加载中…</div>
                </Spin>
                <div v-if="noTable" class="about__tables--notable">暂无启用台桌 如有疑问请联系官方客服 谢谢！</div>
                <div v-else v-for="item in tableData" :key="item.title" class="carditem">
                    <div class="carditem_head">
                        <div class="carditem_head_item">
                            <div class="--countdown">{{ item.countDown || 0 }}</div>
                            <div class="--item">
                                <div>四名宝{{ padZero(item.tableId) }}</div>
                                <div>坐庄:{{ item.operator }}</div>
                            </div>
                        </div>
                        <div class="carditem_head_right">
                            <!-- <img src="../assets/about/people.png"  alt=""> -->
                            <div class="--player">在线:<span>{{ item.players }}</span></div>
                            <div class="--ball-count">
                                <div v-for="el in 4" :key="el.id">
                                    <img :src="require(`../assets/newImage/balls/${el}.png`)" alt="">
                                    <div>{{ item.roadCount[el] }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div :class="['roadmap', { '--close': item.stage < 0 }]" :style="roadMapStyle"
                         @click="gotoTable(item)">
                        <!-- <span v-if="item.stage < 0"  class="cardroad_close">洗牌中</span> -->
                        <div class="roadmap__normal">
                            <div v-for="roadMap in item.roadMap" :key="roadMap.index" class="cardroad_list">
                                <div v-for="road in roadMap" :key="road.index" class="cardroad_item">
                                    <img :src="require(`../assets/newImage/balls/${road[0]}.png`)" alt=""/>
                                </div>
                            </div>
                        </div>
                        <div class="roadmap__oe">
                            <div v-for="roadMapOE in item.roadMapOE" :key="roadMapOE.index" class="cardroad_list">
                                <div v-for="road in roadMapOE" :key="road.index" class="cardroad_item">
                                    <img :src="getBallUrl(road)" alt=""/>
                                </div>
                            </div>
                        </div>
                        <div class="roadmap__ou">
                            <div v-for="roadMapOU in item.roadMapOU" :key="roadMapOU.index" class="cardroad_list">
                                <div v-for="road in roadMapOU" :key="road.index" class="cardroad_item">
                                    <img :src="getBallUrl(road)" alt=""/>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="roadmap_state" v-if="item.countDown && item.countDown > 0" style="font-weight:800">{{ item.countDown }}</div> -->
                        <div class="roadmap_state">{{ item.tablestate }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="loading == true"
            v-load="true"
            load-background="rgba(0, 0, 0, .7)"
            load-type="cube-grid"
            style="height: 200vh; width: 100%; margin-top: -200%"
        />

        <!--    <Main-tab-bar />-->
    </div>
</template>
<script>
import {mapGetters} from "vuex"
import MainTabBar from "../components/Tabbar/MainTabBar.vue"
import PaoMaDeng from "heyushuo-marquee"
import RedPacket from "../components/redPacket/index"
import classTree from "../components/tabbar-class-tree.vue"
import {isEmptyObject} from "../config/index"

export default {
    name: 'About',
    components: {
        MainTabBar,
        PaoMaDeng,
        RedPacket,
        [classTree.name]: classTree,
    },
    data() {
        return {
            userinfo: null,
            show: false,
            iwidth: "",
            iHeight: "",
            country: "台湾",
            loading: false,
            tableLoading: false,
            fGetBackFundpwd: false,
            imgPreview: "",
            imgNext: "",
            imgLast: "",
            scale: 0.3,
            translateX: -250,
            translateY: -100,
            tableData: null,
            limitConfig: {}, //限红设置
            currentInx: 0,
            realHeight: 0,
            timer: "",
            countTime: 0,
            roadData: [[]],
            loginmsg: this.$t("common.logintip"),
            memberlevel: "",
            canShow: false,
            uploadHeaders: {"x-auth-token": localStorage.getItem("TOKEN")},
            form: {
                transferPass: "",
            },
            windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
            windowHeight: document.documentElement.clientHeight, //实时屏幕高度
            rules: {
                transferPass: {
                    required: true,
                    message: this.$t("general.inputTip"),
                    trigger: "blur",
                },
            },
            dialogTitle: "资金密码",
            usernameS: "",
            user: {},
            choseItem: 0,
            accountValue: "1",
            status: 0,
            time1: require("../assets/newImage/11.png"), // 发送验证码倒计时
            time2: require("../assets/newImage/21.png"), // 发送验证码倒计时
            time3: require("../assets/newImage/31.png"), // 发送验证码倒计时
            time4: require("../assets/newImage/41.png"), // 发送验证码倒计时
            sendMsgDisabled1: false,
            sendMsgDisabled2: false,
            sendMsgDisabled3: false,
            sendMsgDisabled5: false,
            moneyList: {},
            coinType: "",
            balanceType: 0,
            sliderSellLimitPercent: 0,
            sliderSellMarketPercent: 120,
            currencyNum: "",
            gameUrl: "",
            hallUrl: "",
            isTg: false,
            transferNo: "",
            transferPass: "",
            disableBtn: false,
            tabBarValue: "1",
            countdown: 60,
            picShow: false,
            picList: [],
            msgList: [],
            moneybab: "",
            timercode: "",
            vLinks: {},
            betTimes: {},
            startRecPacket: false,
            modal: false,
            redData: {},
            activityObj: {},
            roadMapStyle: {},
            bannerHeight: "200px",
            noTable: false,
        }
    },

    methods: {
        goHomeList() {
            this.$router.push({name: "HomeList"})
        },
        preventDefault(e) {
            e.preventDefault()
        },
        disableScroll() {
            window.scrollTo(0, 0)
            document.body.addEventListener("touchmove", this.preventDefault, {
                passive: false,
            })
        },
        desRedPacket(val) {
            this.startRecPacket = false //红包组建销毁
            this.modal = true //唤醒弹窗
            this.redData = val
        },
        handleTabBarChange(value) {
            console.log("LyTabBar change:", value)

            if (value == "charge") {
                this.$router.push("/Recharge")
            } else if (value == "withdraw") {
                this.$router.push("/Withdraw")
            }
        },
        gotoService() {
            // window.location.herf = "https://tawk.to/chat/6327d98e54f06e12d8957824/1gd9qjl6t"

            // window.location.href="https://tawk.to/chat/6327d98e54f06e12d8957824/1gd9qjl6t";//当前标签页
            // window.open("https://tawk.to/chat/6327d98e54f06e12d8957824/1gd9qjl6t");//打开一个新的标签页

            // this.$router.push("/customservice")
            this.$router.push("/me")


        },
        allGame() {
            this.currentInx = 0
        },
        siminbaoGame() {
            this.currentInx = 1
        },
        handleReachBottom() {
        },
        colseWC() {
            this.show = false
            this.$store.commit("setIsGoWC", true)
        },
        silderGo(silder, val) {
            this[silder] = val
        },
        tipFormat(val) {
            this.currencyNum = val
            return val
        },
        beforeUpload(data) {
            if (data && data.size >= 1024000 * 2) {
                this.$Message.error("上传图片大小不能超过2M")
                return false
            }
        },
        isPicNull(val) {
            if (val == null) {
                return true
            }

            return false
        },
        cancel() {
            this.loading = false
            this.$refs.form.resetFields()
        },
        track() {
            document.getElementById("baidu").style.display = "none"
            document.addEventListener("visibilitychange", this.checkBrowserChange)
        },
        checkBrowserChange() {
            if (document.hidden == false) {
                // this.getTime()
                this.countdown = 60
                clearInterval(this.timercode)
            } else {
                let that = this
                this.timercode = setInterval(() => {
                    that.countdown--

                    if (that.countdown <= 0) {
                        clearInterval(that.timercode)
                        that.$store.commit("setMember", null)
                        localStorage.setItem("MEMBER", JSON.stringify(null))
                        localStorage.setItem("TOKEN", null)
                        localStorage.removeItem("USERKEY", null)
                        that.$router.push({path: "/"}).catch(() => {
                        })
                        that.countdown = 60
                    }
                }, 1000)
            }
        },
        frontHandleSuccess(res, file, fileList) {
            this.$refs.upload1.fileList = [fileList[fileList.length - 1]]
            if (res.code == 0) {
                this.frontCardImg = this.imgPreview = res.data
            } else {
                this.$Message.error(res.message)
            }
        },
        backHandleSuccess(res, file, fileList) {
            this.$refs.upload2.fileList = [fileList[fileList.length - 1]]
            if (res.code == 0) {
                this.backCardImg = this.imgNext = res.data
            } else {
                this.$Message.error(res.message)
            }
        },
        handHandleSuccess(res, file, fileList) {
            this.$refs.upload3.fileList = [fileList[fileList.length - 1]]
            if (res.code == 0) {
                this.handCardImg = this.imgLast = res.data
            } else {
                this.$Message.error(res.message)
            }
        },
        noPhone() {
            this.$Message.info(this.$t("uc.safe.bindphonetip"))
            this.showItem(3)
        },
        showItemFundpwd() {
            this.fGetBackFundpwd = false
            this.handleReset("formValidate5")
            this.showItem(5)
        },
        renderPw() {
            this.$Modal.confirm({
                title: this.$t("uc.safe.resetfundpwd"),
                onOk: () => {
                    this.$Message.info("Clicked ok")
                },
                render: (h) => {
                    return h("Input", {
                        props: {
                            value: this.value,
                            autofocus: true,
                        },
                        on: {
                            input: (val) => {
                                this.value = val
                            },
                        },
                    })
                },
            })
        },
        handleSubmit(name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    this.submit(name)
                } else {
                    this.$Message.error(this.$t("uc.safe.save_failure"))
                }
            })
        },
        handleReset(name) {
            this.$refs[name].resetFields()
        },
        showItem(index) {
            this.choseItem = index
        },
        goToWC() {
            var self = this

            if (this.$store.getters.isNobody == true) {
                this.$router.push({
                    path: "/GamePage",
                    query: {gpage: this.gameUrl, gid: "100"},
                })
                return
            }

            var params = {}
            const element = this.moneyList[1]

            this.balanceType = element.amount

            params["score"] = Number(this.balanceType)
            params["ccy"] = element.ccy

            this.$http
                .post(self.host + this.api.uc.myInnovationMinings, params)
                .then((response) => {
                    var resp = response.body
                    if (resp.code == "0000") {
                        self.getGameUrl(0)
                        // this.$router.push({ path: '/GamePage', query: { gpage: this.gameUrl } })
                    } else {
                        // self.$Message.error(resp.mesg);
                        this.$Notice.error({
                            title: this.$t("common.tip"),
                            desc: resp.mesg,
                        })
                        // this.$Message.error(this.$t('common.logintip'));
                    }
                })
        },
        goToGame() {
            // if (this.$store.getters.isNobody == true) {
            //   this.$router.push({ path: '/GamePage', query: { gpage: this.gameUrl , gid: '80' } })
            //   return
            // }
            this.$router.push({path: "/GamePage"})
        },
        gotoHall() {
            var self = this

            if (this.$store.getters.isNobody == true) {
                this.$router.push({
                    path: "/GamePage",
                    query: {gpage: this.hallUrl, gid: "0"},
                })
                return
            }

            var params = {}
            const element = this.moneyList[1]

            this.balanceType = element.amount

            // params['score'] = Number(this.balanceType)
            params["ccy"] = element.ccy

            this.$http
                .post(self.host + this.api.uc.myInnovationMinings, params)
                .then((response) => {
                    var resp = response.body
                    if (resp.code == "0000") {
                        self.getGameUrl(2)
                        // this.$router.push({ path: '/GamePage', query: { gpage: this.hallUrl } })
                    } else {
                        // self.$Message.error(resp.mesg);
                        this.$Notice.error({
                            title: this.$t("common.tip"),
                            desc: resp.mesg,
                        })
                        // this.$Message.error(this.$t('common.logintip'));
                    }
                })
        },
        wcShow() {
            this.show = true
        },
        gotocharge() {
            this.$router.replace("/Recharge")
        },
        gotoWithdraw() {
            this.$router.replace("/Withdraw")
        },
        gotoLogin() {
            this.$router.replace("/Home")
        },
        gotoReg() {
            this.$router.replace("/MobileRegister")
        },
        getGameUrl(type) {
            var self = this
            let gameid = 0
            //  switch (type) {
            //   case 0:
            //     gameid = '100';
            //     break;
            //  case 1:
            //   gameid = '80';
            //   break
            // case 2:
            //   gameid = '0'
            //   break
            //   default:
            //     break;
            //  }

            let param = {}
            param.gameId = "" + type
            this.$http
                .post(this.host + this.api.uc.myInnovationLocked, param)
                .then((response) => {
                    var resp = response.body
                    if (resp.code == "0000") {
                        // self.user = resp.data;
                        // self.usernameS = this.user.username.slice(0,1);
                        self.gameUrl = resp.data
                        console.log("sdfsj" + self.gameUrl)
                        const myArray = self.gameUrl.split("&")
                        self.hallUrl = myArray[0] + "&" + myArray[2]
                        // console.log("sdfsj11111" + self.hallUrl)

                        this.$router.push({
                            path: "/GamePage",
                            query: {gpage: self.gameUrl, gid: gameid},
                        })
                    } else {
                        // self.$Message.error(resp.mesg);
                        this.$Notice.error({
                            title: this.$t("common.tip"),
                            desc: resp.mesg,
                        })
                        // this.$Message.error(this.$t('common.logintip'));
                    }
                })
        },
        getmarquee() {
            var self = this
            this.$http.post(self.host + this.api.uc.toppromotion).then((response) => {
                var resp = response.body
                if (resp.code == "0000") {
                    self.msgList = resp.data
                } else {
                    // self.$Message.error(resp.mesg);
                    this.$Notice.error({
                        title: this.$t("common.tip"),
                        desc: resp.mesg,
                    })
                }
            })
        },
        getMember() {
            //获取个人安全信息
            let self = this
            this.loading = true
            this.$http
                .post(self.host + this.api.uc.personalWallet)
                .then((response) => {
                    let resp = response.body
                    self.loading = false
                    if (resp.code == "0000") {
                        // self.user = resp.data;
                        // self.usernameS = this.user.username.slice(0,1);
                        self.moneyList = resp.data
                        self.moneyList.forEach((element) => {
                            if (element.ccy == "USDT") {
                                self.moneybab = element
                            }
                        })
                        // if (this.isTg) {
                        //   this.changeCoin()
                        //   this.sliderSellMarketPercent = Number(this.$store.getters.amount)
                        // }
                    } else {
                        // self.$Message.error(resp.mesg);
                        this.$Notice.error({
                            title: this.$t("common.tip"),
                            desc: resp.mesg,
                        })
                        // this.$Message.error(this.$t('common.logintip'));
                    }
                })
        },
        changeCoin(value) {
            console.log(value)
            this.sliderSellMarketPercent = 0
            for (let index = 0; index < this.moneyList.length; index++) {
                const element = this.moneyList[index]
                if (element.ccy == this.coinType) {
                    this.balanceType = element.amount
                }
            }
        },
        // caculRealHeight() {
        //   const windowHeight = document.documentElement.clientHeight
        //   // this.realHeight = Number(windowHeight) * (1 - 200 / windowHeight)
        //   this.realHeight = Number(windowHeight) * 0.68 - 60 // marginTop: 32vh, footer: 60px
        //   console.log("sdfs::::" + this.realHeight)
        // },
        getMemberInfo() {
            //获取个人安全信息
            var self = this

            this.$http.post(self.host + this.api.uc.memberInfo).then((response) => {
                var resp = response.body
                if (resp.code == "0000") {
                    localStorage.setItem("MEMBER", null)
                    self.$store.commit("setMember", resp.data)
                    self.userinfo = resp.data
                } else {
                    self.$Message.error(resp.mesg)
                }
            })
        },
        handleTableData(tables) {
            for (const i in tables) {
                let element = tables[i]
                element.players = element.users.length
                element.fakePlayers = element.fakeUsers
                //    element.avatar = require('@/assets/newImage/1.png')
                this.getGameConfig(element)
                switch (element.stage) {
                    case -1:
                        element.tablestate = "台桌关闭"
                        break
                    case 0:
                        element.tablestate = "台桌开始"
                        break
                    case 1:
                        element.tablestate = "开始下注"
                        break
                    case 2:
                        element.tablestate = "结束下注"
                        break
                    case 3:
                        element.tablestate = "结算"
                        break
                    default:
                        break
                }
                element.historyPeriod.map(item => { //之前的数据格式改了  result 1 这个字段改为了fdsbd_1
                    item.result = item.result.slice(-1)
                })
                this.roadData[element.tableId] = this.handleRoadData(element.historyPeriod)
                element.roadMap = this.handleRoadData(element.historyPeriod),
                    element.roadMapOE = this.handleOtherRoadData(element.historyPeriod, 'OE')
                element.roadMapOU = this.handleOtherRoadData(element.historyPeriod, 'OU')
                element.roadCount = this.handleRoadCount(element.historyPeriod)
            }
        },
        handleTableData1(tables) {
            for (const i in tables) {
                let element = tables[i]
                element.players = element.users.length
                element.fakePlayers = element.fakeUsers
                //    element.avatar = require('@/assets/newImage/1.png')
                switch (element.stage) {
                    case -1:
                        element.tablestate = "台桌关闭"
                        break
                    case 0:
                        element.tablestate = "台桌开始"
                        break
                    case 1:
                        element.tablestate = "开始下注"
                        break
                    case 2:
                        element.tablestate = "结束下注"
                        break
                    case 3:
                        element.tablestate = "结算"
                        break
                    default:
                        break
                }
                element.historyPeriod.map(item => { //之前的数据格式改了  result 1 这个字段改为了fdsbd_1
                    item.result = item.result.slice(-1)
                })
                this.roadData[element.tableId] = this.handleRoadData(element.historyPeriod)
                element.roadMap = this.handleRoadData(element.historyPeriod),
                    element.roadMapOE = this.handleOtherRoadData(element.historyPeriod, 'OE')
                element.roadMapOU = this.handleOtherRoadData(element.historyPeriod, 'OU')
                element.roadCount = this.handleRoadCount(element.historyPeriod)
            }
        },
        // handleRoadData(road) {
        //   const tempRoad = []

        //   Object.values(road).forEach((value) => {
        //     const [lastValue] = tempRoad.slice(-1)

        //     if (!lastValue || lastValue.length <= 0) {
        //       tempRoad.push([[value.result, 0]])
        //     } else if (lastValue.length >= 6) {
        //       const [lastElement] = lastValue.slice(-1)

        //       if (
        //         (Number(lastElement[0]) % 2 == 0 &&
        //           Number(value.result) % 2 == 0) ||
        //         (Number(lastElement[0]) % 2 == 1 && Number(value.result) % 2 == 1)
        //       ) {
        //         tempRoad.push([[value.result, 1]])
        //         lastValue.forEach((element) => {
        //           element[1] = 1
        //         })
        //       } else {
        //         tempRoad.push([[value.result, 0]])
        //       }
        //     } else {
        //       const [lastElement] = lastValue.slice(-1)

        //       if (lastElement !== undefined && lastElement !== null) {
        //         if (
        //           (Number(lastElement[0]) % 2 === 0 &&
        //             Number(value.result) % 2 === 0) ||
        //           (Number(lastElement[0]) % 2 === 1 &&
        //             Number(value.result) % 2 === 1)
        //         ) {
        //           lastValue.push([value.result, lastElement[1]])
        //         } else {
        //           tempRoad.push([[value.result, 0]])
        //         }
        //       } else {
        //         console.error("!!!!!!!!!!")
        //       }
        //     }
        //   })

        //   return tempRoad.slice(-16)
        // },
        handleRoadData(road) { // 一般路圖
            return Object.values(road).reduce((roadMap, val) => {
                const [lastRow] = roadMap.slice(-1)

                if (!lastRow || lastRow.length <= 0 || lastRow.length >= 6) {
                    roadMap.push([[val.result, 0]])
                } else {
                    lastRow.push([val.result, 0])
                }

                return roadMap

            }, []).slice(-6)
        },
        handleOtherRoadData(road, type) { // 單雙大小路圖
            return Object.values(road).reduce((roadMap, val) => {
                const [lastValue] = roadMap.slice(-1)
                const currentResult = Number(val.result)
                const currentResultText = type === 'OU'
                    ? (currentResult > 2 ? 'da' : 'xiao')
                    : (currentResult % 2 === 0 ? 'shuang' : 'dan')

                if (!lastValue || lastValue.length === 0) {
                    roadMap.push([[currentResult, 0, currentResultText]])
                } else {
                    const [lastElement] = lastValue.slice(-1)
                    const lastElementResult = Number(lastElement[0])
                    const isSameParity = type === 'OU' // OE: 單雙，OU: 大小
                        ? lastElementResult > 2 === currentResult > 2 // 判斷大小
                        : lastElementResult % 2 === currentResult % 2 // 判斷單雙

                    if (lastValue.length >= 6) { // 一行最多顯示6個號碼
                        roadMap.push([[currentResult, isSameParity ? 1 : 0, currentResultText]])
                        isSameParity && lastValue.forEach(element => element[1] = 1) // 將之前的號碼改為長龍
                    } else {
                        if (isSameParity) {
                            lastValue.push([currentResult, lastElement[1], currentResultText])
                        } else {
                            roadMap.push([[currentResult, 0, currentResultText]])
                        }
                    }
                }
                return roadMap
            }, []).slice(-20)
        },
        handleRoadCount(road) {
            const test = Object.values(road).reduce((roadCount, {result}) => {
                roadCount[result]++
                return roadCount
            }, {'1': 0, '2': 0, '3': 0, '4': 0})

            return test
        },
        getGameConfig(element) {
            //获取游戏配置
            if (!element) {
                return
            }

            let that = this
            let param = {}
            param.tableId = element.tableId
            this.$http
                .get(this.host + this.api.game.getConfig, {params: param})
                .then((response) => {
                    let resp = response.body
                    // that.tableLoading = false
                    if (!response.ok) {
                        that.$Message.error("获取游戏配置异常")
                    } else {
                        element.limitBet = ""
                        element.limitRed = ""
                        element.videoLink = resp.videoLink
                        element.betTime = resp.betTime
                        that.betTimes[element.tableId] = element.betTime
                        that.vLinks[element.tableId] = element.videoLink

                        if (that.timer) {
                            clearInterval(that.timer)
                        }

                        that.timer = setInterval(that.getTables1, 1000)
                        this.tableLoading = false
                    }
                })

            console.log("ssss" + element)
        },
        gotoTable({tableId, operator, currentPeriod, stage}) {
            if (stage < 0) {
                return
            }
            if (!this.vLinks[tableId]) {
                this.$Message.error("获取游戏配置异常")
                return
            }
            this.$router.push({
                path: "/gameTable",
                query: {
                    gpage: tableId,
                    page1: operator,
                    pp: this.vLinks[tableId],
                    pp1: this.betTimes[tableId],
                    pp2: currentPeriod.period,
                },
            })
        },
        getTables() {
            //获取桌子
            let that = this
            that.tableLoading = true

            this.$http.get(this.host + this.api.game.getTables).then((response) => {
                const resp = response.body
                if (resp.code == 200) {
                    that.tableData = resp.data

                    // 判斷是否有開啟的台桌
                    if (isEmptyObject(that.tableData)) {
                        that.tableLoading = false
                        that.noTable = true
                        return
                    }

                    that.noTable = false
                    that.handleTableData(that.tableData)
                }
            })
        },
        getTables1() {
            let that = this
            this.$http.get(this.host + this.api.game.getTables).then((response) => {
                const resp = response.body
                // console.log(resp)
                if (resp.code == 200) {
                    that.tableData = resp.data

                    // 判斷是否有開啟的台桌
                    if (isEmptyObject(that.tableData)) {
                        that.tableLoading = false
                        that.noTable = true
                        return
                    }

                    that.noTable = false
                    that.handleTableData1(that.tableData)
                }
            })
        },
        loadPicData() {
            let param = {}
            param.adType = "INDEX_BANNER"
            this.$http
                .get(this.host + this.api.uc.mycardlist, {params: param})
                .then((response) => {
                    var result = response.body
                    if (result.code == "0000") {
                        this.picList = result.data
                        this.picShow = true
                    } else {
                        this.picShow = false
                    }
                })
        },
        padZero(num) {
            return num.toString().padStart(2, "0")
        },
        getElementSize() {
            const windowWidth = window.innerWidth
            const roadWidth = Math.min(windowWidth, 700) - (8 + 4) * 2 // (margin + padding) * 2
            this.bannerHeight = `${Math.floor(windowWidth * 205 / 430)}px` // banner比例 430 * 205
            this.roadMapStyle = {height: `${Math.floor((roadWidth * 300) / 800)}px`} // 等比處理：roadmap背景圖size 800*300
            this.realHeight = Number(windowWidth) * 0.68 - 60 // marginTop: 32vh, footer: 60px
        },
        getBallUrl(element) {
            if (!Array.isArray(element)) {
                return null
            }

            const [, , resultText] = element
            return require(`../assets/newImage/balls/${resultText}.png`)
        },
    },
    computed: {
        ...mapGetters(['member', 'isLogin', 'lang']),
        sliderSellDisabled() {
            if (this.coinType !== "" && this.isTg != true) {
                return false
            }
            return true
        },
        src() {
            return require(`../assets/newImage/all${this.currentInx === 0 ? '' : '1'}.png`)
        },
    },
    created() {
        this.iwidth = document.documentElement.clientWidth
        this.iHeight = document.documentElement.clientHeight
        this.loadPicData()
        this.getmarquee()
        window.scrollTo(0, 0)

        let localtoken = localStorage.getItem("TOKEN")
        if (localtoken) {
            this.getMember()
            this.getMemberInfo()
        }

        this.getTables()
        this.getGameConfig()
        this.currentInx = 0

        // 監聽resize處理，暫拿掉
        // this.caculRealHeight()
    },

    mounted() {
        this.getElementSize()
        window.addEventListener('resize', this.getElementSize)
    },
    destroyed() {
        clearInterval(this.timercode)
        clearInterval(this.timer)
        this.timercode = null
        this.startRecPacket = false //防止点击到其他位置 离开销毁

        window.removeEventListener('resize', this.getElementSize)
    },
}
</script>

<style lang="scss">

.covef {
    position: absolute;
    background: url(../assets/newImage/66.png);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: none;
}

.about {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    color: #fff;
    font-size: 14px;

    height: $full-page;

    background-size: 100% 100%;
    background: url("../assets/bg.png") no-repeat center;

    .about__head {
        position: relative;
        height: 50px;
        background-image: url("../assets/ting/game_top_bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    &__head {
        background: url(../assets/newImage/1234.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
        display: flex;
        justify-content: flex-end;
        position: fixed;
        top: 0px;
        right: 0;
        left: 0;
        height: 70px;
        padding: 5px 10px 10px;
        z-index: 100;
        width: 100%;

        &--avatar {
            img {
                width: 55px;
                height: 55px;
            }
        }

        &--info {
            width: 100%;
            display: flex;
            justify-content: space-around;

            & > div {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 3px 5px 2px;
                font-weight: bold;
                //border: 1px solid #353535;
                border-radius: 8px;
                //background-color: #36343486;
                color: #ECCC80;

                & + div {
                    margin-top: 3px;
                }

                img {
                    width: 20px;
                    height: auto;
                    margin-right: 8px;
                }
            }
        }

        &--service {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-width: 60px;
            border: 1px solid #353535;
            background-color: #21202086;
            border-radius: 8px;
            font-size: small;

            img {
                width: 20px;
                margin-right: 4px;
            }
        }
    }

    &__content {
        height: calc(100vh - 90px);
        margin-top: 10px;
        padding-bottom: 60px;
        overflow-y: auto;
        background: transparent;
    }

    &__banner {
        img {
            width: 100%;
            height: auto;
        }
    }

    &__propaganda {
        display: flex;
        align-items: center;
        width: 100%;
        height: 4vh;
        border-top: 1px solid #515153;
        // background-color: #161616f9;
        // margin-top: 1vh;
        &--bg {
            display: flex;
            align-items: center;
            width: 100%;
            height: 60%;
            padding: 0 3% 0 11%;
            color: #fff;
            box-sizing: border-box;
            background-image: url(../assets/netmage/lababack.png);
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: 100% 100%;

            .wrap.--wrap {
                background: transparent;
                font-size: 12px;
            }
        }
    }

    &__midhead {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 30px;
        padding: 0 20px;
        background: transparent;
        // background: url(../assets/newImage/game_btn_box_bg.jpg);
        // background-position: center;
        // background-repeat: no-repeat;
        // background-size: 97% 100%;
        // border-top: 1px solid #515153;
        // box-shadow: 0 2px 6px 1px #171717;
        z-index: 1;

        & > div {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        img {
            width: 30px;
            height: auto;
        }
    }

    &__tables {
        position: relative;
        min-height: 30%;
        background: transparent;

        &--notable {
            margin: 30% 0;
        }
    }
}
</style>
<style scoped lang="scss">
button.ivu-btn {
    &:focus {
        box-shadow: 0 0 0 2px rgba(45, 140, 240, 0);
    }
}

button.ivu-btn.ivu-btn-primary {
    box-shadow: 0 0 0 2px rgba(45, 140, 240, 0);
}

.detail-list .input-control .ivu-input-group-prepend {
    width: 63px;
}

.detail-list .input-control .ivu-input {
    height: 45px;
}

.ivu-list-split .ivu-list-item {
    border-bottom: 0px solid #e8eaec;
}

.active {
    background-image: url(../assets/newImage/game_btn_on.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5px;
    // margin-top: 5px;
    // margin-left: 20px;
    // color: #f2f4f5;
    // display: flex;
    // align-items: center;
}

.unactive {
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5px;
}

.carditem {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 8px;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 5px;
    border: none;
    background-color: #262931;
    border: 1px solid #a19a6b;
    border-radius: 8px;

    & + & {
        margin-top: 8px;
    }

    &_head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 70px;
        padding-left: 10px;
        // padding: 0 10px 0 20%;
        color: #efe7e7;
        // background-color: #021427;
        // background: url("../assets/about/table-head-bg.png") no-repeat;
        background-size: 100% 100%;
        font-size: 18px;
        // font-weight: 900;

        &_item {
            display: flex;
            // flex-direction: column;
            align-items: center;
            justify-content: center;
            // margin: 1px 0;
            // padding: 0 10px;

            .--countdown {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 45px;
                height: 45px;
                color: #c4cb0d;
                font-size: 30px;
                font-weight: bold;
                border: 1px solid #e5c97a;
                border-radius: 8px;
            }

            .--item {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin: 1px 0;
                padding: 0 10px;

                & > div:first-child {
                    // color: #f5d989;
                    font-weight: bold;
                    font-size: 20px;
                }
            }

        }

        &_right {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;

            .--player {
                padding-right: 6px;

                span {
                    color: #12b4b4;
                    font-weight: 700;
                }
            }


            .--ball-count {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 2px 4px;
                background: rgba(0, 0, 0, .5);
                border: 1px solid #4f4f55;
                border-radius: 20px;

                & > div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 4px;
                }

                img {
                    width: 20px;
                    height: auto;
                    margin-right: 4px;
                }
            }

            // img {
            //   width: 15px;
            //   height: auto;
            //   margin-right: 8px;
            // }
        }
    }
}

.roadmap {
    display: flex;
    position: relative;
    margin: 4px 0;
    width: 100%;
    max-width: 676px;
    background: url(../assets/newImage/cardroad_bg_1.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    &__normal,
    &__oe,
    &__ou {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
    }

    &__normal {
        width: 100%;
        height: 100%;
    }

    &__oe,
    &__ou {
        left: 37.5%;
        width: 62.5%;
        height: 50%;

        .cardroad_list {
            width: 5%;
        }
    }

    &__ou {
        top: 50%;
    }

    &.--close {
        opacity: 0.5;
    }

    &_state {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 50%;
        color: #00000071;
        font-weight: 500;
        font-size: 2.2em;
        transform: translateY(-50%);
        z-index: 200;
    }
}

// .roadmap1 {
//   position: relative;
//   background: url(../assets/newImage/cardroad_bg_16.png);
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: contain;
//   width: 100%;
//   display: flex;
//   opacity: 0.5;
// }

.cardroad_close {
    margin-top: 10%;
    margin-left: 20%;
    color: brown;
    font-weight: 600;
    font-size: xx-large;
}

.cardroad_list {
    width: 6.25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cardroad_item {
    // width: 95%;
    height: calc(100% / 6);
    display: flex;
    align-items: center;
    justify-content: center;
    // scale: 0.9;
    padding-top: 1px;
    padding-left: 1px;

    img {
        width: 100%;
        height: 90%;
    }
}

.ball-line {
    position: absolute;
    width: 2px;
    // height: 105%;
    top: 0;
    bottom: 0;
    right: 25%;
    background-color: #000;
    z-index: 100;
}

.demo-spin-col {
    position: absolute;
    top: 50%;
    left: 0;
    height: calc(100% - 35vh);
    background-color: rgba(0, 0, 0, .7);

    &.ivu-spin {
        color: #ccc;
    }
}

.demo-spin-icon-load {
    animation: ani-demo-spin 1s linear infinite;
}

@keyframes ani-demo-spin {
    from {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@media (max-width: 500px) {
    .carditem_head {
        height: 50px;
        font-size: 14px;

        &_item .--item > div:first-child {
            font-size: 16px;
        }
    }
}

.about__head--avatar {
    position: absolute;
    left: 4px;
    top: 0px;

    & > img {
        padding: 12px;
    }
}

.about__head--service {
    display: flex;
    flex-direction: row;
    margin-right: 4%;
    font-size: 12px;
}
</style>
